import React from 'react';

const CookiePolicy = () => {
  const lastUpdated = "October 28, 2024";

  return (
    <div className="min-h-screen bg-[#F7F7EF] p-8">
      <div className="max-w-4xl mx-auto font-mono">
        <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>
        <p className="text-sm mb-8">Last Updated: {lastUpdated}</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="mb-4">
              This Cookie Policy explains how ReelVoodoo uses cookies and similar technologies 
              to recognize and enhance your experience when you visit our AI-powered movie 
              recommendation service.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. What Are Cookies?</h2>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <p className="mb-4">
                Cookies are small text files that are stored on your device when you visit 
                a website. They help us:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Recognize your device</li>
                <li>Remember your preferences</li>
                <li>Improve your experience</li>
                <li>Provide essential service functionality</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Types of Cookies We Use</h2>
            <div className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Essential Cookies:</h3>
                <p className="text-sm mb-2 text-gray-600">Required for basic service operation</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Authentication cookies for Google Sign-In</li>
                  <li>Session management</li>
                  <li>Security tokens</li>
                  <li>Service functionality</li>
                  <li>Token usage tracking</li>
                </ul>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Functional Cookies:</h3>
                <p className="text-sm mb-2 text-gray-600">Enable personalized features</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Language preferences</li>
                  <li>Theme settings</li>
                  <li>User interface customizations</li>
                  <li>Chat history persistence</li>
                  <li>Movie preference storage</li>
                </ul>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">AI Interaction Cookies:</h3>
                <p className="text-sm mb-2 text-gray-600">Specific to our recommendation system</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Conversation context</li>
                  <li>User preferences</li>
                  <li>Recommendation history</li>
                  <li>Interaction patterns</li>
                  <li>Response customization</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Managing Your Cookie Preferences</h2>
            <div className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Browser Controls:</h3>
                <p className="mb-2">You can manage cookies through your browser settings to:</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Block all cookies</li>
                  <li>Delete existing cookies</li>
                  <li>Allow only certain cookies</li>
                  <li>Configure site-specific settings</li>
                </ul>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Impact of Disabling Cookies:</h3>
                <p className="mb-2">Disabling cookies may affect:</p>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Google authentication</li>
                  <li>Personalized recommendations</li>
                  <li>Chat history preservation</li>
                  <li>User preferences</li>
                  <li>Service functionality</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Updates to This Policy</h2>
            <p className="mb-4">
              We may update this Cookie Policy from time to time. We will notify you of any 
              changes by posting the new policy on this page and updating the "Last Updated" date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Contact Information</h2>
            <p>
              For cookie-related inquiries, please contact us at:{' '}
              <a href="mailto:privacy@reelvoodoo.com" className="text-blue-600 hover:underline">
                privacy@reelvoodoo.com
              </a>
            </p>
          </section>

          <section className="border-t pt-8">
            <p className="text-sm text-gray-600">
              By continuing to use ReelVoodoo, you agree to our use of cookies as described 
              in this Cookie Policy. You can manage your cookie preferences at any time through 
              your browser settings or our cookie management interface.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;