import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { savePendingConsents } from '../components/LegalComponents';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      // console.log('checking auth');
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
      
      // Get auth status from URL parameters if they exist (for Safari redirect)
      const params = new URLSearchParams(window.location.search);
      const safariUserId = params.get('user_id');
      const safariUsername = params.get('username');
      const token = params.get('token');
      
      // If we have Safari parameters, try to set the session
      if (isSafari && safariUserId && safariUsername && token) {
        // console.log('Setting Safari session');
        try {
          const safariResponse = await axios.post(
            `${API_URL}/api/set_safari_session`,
            {
              user_id: safariUserId,
              username: safariUsername,
              token: token
            },
            { 
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          
          if (safariResponse.data.success) {
            // console.log('Safari session set successfully');
            setUser({
              authenticated: true,
              user_id: safariUserId,
              username: safariUsername
            });
            return;
          }
        } catch (safariError) {
          // console.error('Failed to set Safari session:', safariError);
          // Fall through to regular auth check if Safari session setting fails
        }
      }

      // Regular auth check for all browsers
      // console.log('Performing regular auth check');
      const response = await axios.get(`${API_URL}/api/check_auth`, { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.authenticated) {
        // console.log('User is authenticated:', response.data);
        setUser(response.data);
        await savePendingConsents();
      } else {
        // console.log('User is not authenticated');
        setUser(null);
      }
    } catch (error) {
      // console.error('Auth check failed:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      // console.log('Logging out user');
      await axios.post(
        `${API_URL}/api/logout`, 
        {}, 
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setUser(null);
      // console.log('Logout successful');
    } catch (error) {
      // console.error('Logout failed:', error);
      // Still clear the local user state even if the server request fails
      setUser(null);
    }
  };

  const refreshAuth = async () => {
    // console.log('Refreshing authentication');
    await checkAuthStatus();
  };

  const value = {
    user,
    loading,
    logout,
    isAuthenticated: !!user,
    checkAuthStatus,
    refreshAuth
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;