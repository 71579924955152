import React, { useRef, useEffect, useState } from 'react';
import { Send, Menu, LogOut, Mail } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

const ThinkingAnimation = () => {
  return (
    <div className="inline-flex">
      {[0, 1, 2].map((i) => (
        <motion.span
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: "reverse",
            delay: i * 0.2,
          }}
        >
          .
        </motion.span>
      ))}
    </div>
  );
};

const AutoExpandingTextarea = ({ value, onChange, placeholder, onKeyDown, disabled }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      disabled={disabled}
      className="flex-1 p-2 mr-2 bg-[#F7F7EF] border-2 border-[#202124] text-[#202124] placeholder-[#202124] focus:outline-none rounded-lg font-mono resize-none overflow-hidden disabled:opacity-50 disabled:cursor-not-allowed"
      rows="1"
      style={{ minHeight: '40px', maxHeight: '120px' }}
    />
  );
};

const EnhancedBasquiatChat = ({ 
  messages, 
  input, 
  setInput, 
  handleSend, 
  handleLogout, 
  isBotThinking,
  tokensRemaining,
  hasReachedLimit
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleContactClick = () => {
    window.location.href = 'mailto:hello@reelvoodoo.com';
  };

  const chatContainerStyles = {
    width: '100%',
    maxWidth: '40rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F7F7EF',
    marginBottom: '150px',
    marginTop: '2rem',
    fontFamily: '"Courier New", Courier, monospace',
  };

  const chatContentStyles = {
    padding: '1rem',
  };

  const isImageUrl = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const isMoviePosterUrl = (url) => {
    return url.toLowerCase().includes('images') && isImageUrl(url);
  };

  const inputAreaStyles = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#F7F7EF',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: '"Courier New", Courier, monospace',
  };

  const inputContentStyles = {
    width: '100%',
    maxWidth: '40rem',
  };

  const canUserSendMessage = messages.length === 0 || !messages[messages.length - 1].user;
  const isInputNotEmpty = input.trim() !== '';
  const showSendButton = canUserSendMessage && isInputNotEmpty && !hasReachedLimit;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (showSendButton) {
        handleSend();
      }
    }
  };

  const formatMarkdownText = (text) => {
    return text.replace(/(?<!\n)\n(?!\n)/g, ' ').replace(/\n\n/g, '\n\n');
  };

  return (
    <>
      <div className="fixed top-0 right-0 m-4 z-50" ref={menuRef}>
        <motion.button
          className="p-2 bg-[#F7F7EF] hover:bg-[#202124] text-[#202124] hover:text-[#F7F7EF] rounded-lg transition-colors shadow-md"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Menu size={24} />
        </motion.button>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="absolute top-full right-0 mt-2 bg-[#F7F7EF] border-2 border-[#202124] rounded-lg shadow-lg"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              <button
                className="flex items-center w-full px-4 py-2 hover:bg-[#202124] hover:text-[#F7F7EF] transition-colors"
                onClick={handleLogout}
              >
                <LogOut size={16} className="mr-2" />
                Logout
              </button>
              <button
                className="flex items-center w-full px-4 py-2 hover:bg-[#202124] hover:text-[#F7F7EF] transition-colors"
                onClick={handleContactClick}
              >
                <Mail size={16} className="mr-2" />
                Contact
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Token Limit Warning */}
      <AnimatePresence>
        {hasReachedLimit && (
          <motion.div
            className="fixed top-20 left-4 right-4 z-50 max-w-xl mx-auto"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <div className="bg-[#202124] text-[#F7F7EF] p-6 rounded-lg shadow-lg border-2 border-[#F7F7EF] text-center font-mono">
              <h3 className="font-bold text-xl mb-4">Token Limit Reached</h3>
              <p className="mb-6">You've reached the token limit for this beta version. Thank you for participating!</p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleLogout}
                  className="flex items-center px-4 py-2 bg-[#F7F7EF] text-[#202124] rounded-lg hover:bg-opacity-90 transition-colors"
                >
                  <LogOut size={16} className="mr-2" />
                  Logout
                </button>
                <button
                  onClick={handleContactClick}
                  className="flex items-center px-4 py-2 bg-[#F7F7EF] text-[#202124] rounded-lg hover:bg-opacity-90 transition-colors"
                >
                  <Mail size={16} className="mr-2" />
                  Contact Us
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Low Tokens Warning */}
      <AnimatePresence>
        {tokensRemaining !== null && tokensRemaining < 1000 && !hasReachedLimit && (
          <motion.div
            className="fixed top-20 left-4 right-4 z-50 max-w-xl mx-auto"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <div className="bg-[#202124] text-[#F7F7EF] p-4 rounded-lg shadow-lg border-2 border-[#F7F7EF] text-center font-mono">
              <p>You have {tokensRemaining} tokens remaining in this beta version</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div style={chatContainerStyles}>
        <div style={chatContentStyles}>
          <header className="font-bold mb-6 flex flex-col items-center justify-between">
            <span className="text-5xl text-[#202124] px-4 py-3 transform -rotate-2 inline-flex items-baseline mb-2">
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>R</span>
              <span className="font-mono">EEL</span>
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>V</span>
              <span className="font-mono">OO</span>
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>D</span>
              <span className="font-mono">OO</span>
            </span>
            <span className="text-4xl text-[#202124] px-4 py-3 transform inline-flex items-baseline">
              <span className="font-mono">CIN</span>
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>É</span>
              <span className="font-mono">MA</span>
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>TH</span>
              <span className="font-mono">È</span>
              <span style={{fontFamily: "'Permanent Marker', cursive"}}>QUE</span>
            </span>
          </header>
          
          {messages.map((msg, index) => (
            <motion.div
              key={index}
              className={`mb-6 ${msg.user ? 'text-right' : 'text-left'}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              {msg.user ? (
                <span className="inline-block p-2 bg-[#202124] text-[#F7F7EF] rounded-lg text-left break-words whitespace-pre-wrap max-w-[80%]">
                  {msg.text}
                </span>
              ) : (
                <span className="inline-block text-[#202124] break-words whitespace-pre-wrap max-w-[100%]">
                  <ReactMarkdown
                    components={{
                      a: ({ node, href, ...props }) => {
                        if (isMoviePosterUrl(href)) {
                          return (
                            <img
                              src={href}
                              alt="Movie Poster"
                              className="max-w-full h-auto rounded-lg shadow-md my-2"
                              style={{ maxHeight: '300px' }}
                            />
                          );
                        }
                        return (
                          <a
                            href={href}
                            className="text-blue-600 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            {...props}
                          />
                        );
                      },
                      p: ({ node, ...props }) => <p className="mb-2 whitespace-pre-wrap" {...props} />,
                      h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-2" {...props} />,
                      h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-2" {...props} />,
                      h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props} />,
                      ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-2" {...props} />,
                      ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-2" {...props} />,
                      li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                      code: ({ node, inline, ...props }) => 
                        inline ? (
                          <code className="bg-gray-100 rounded px-1" {...props} />
                        ) : (
                          <pre className="bg-gray-100 rounded p-2 overflow-x-auto">
                            <code {...props} />
                          </pre>
                        ),
                    }}
                  >
                    {formatMarkdownText(msg.text)}
                  </ReactMarkdown>
                </span>
              )}
            </motion.div>
          ))}
          
          {isBotThinking && (
            <motion.div
              className="mb-6 text-left"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <span className="inline-block text-[#202124]">
                <ThinkingAnimation />
              </span>
            </motion.div>
          )}
        </div>
      </div>

      <div style={inputAreaStyles}>
        <div style={inputContentStyles}>
          <div className="flex items-end mb-2">
            <AutoExpandingTextarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={hasReachedLimit ? "Token limit reached" : "Type your message..."}
              onKeyDown={handleKeyDown}
              disabled={hasReachedLimit}
            />
            <AnimatePresence>
              {showSendButton && (
                <motion.button
                  onClick={handleSend}
                  className="p-2 bg-[#202124] text-[#F7F7EF] hover:bg-[#F7F7EF] hover:text-[#202124] border-2 border-[#202124] transition-colors rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.2 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={hasReachedLimit}
                >
                  <Send size={24} />
                </motion.button>
              )}
            </AnimatePresence>
          </div>


  {tokensRemaining !== null && !hasReachedLimit && (
            <motion.div
              className="text-center text-sm text-[#202124] mt-2 font-mono"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {tokensRemaining} tokens remaining
            </motion.div>
          )}

          {/* Limit Reached Message */}
          {hasReachedLimit && (
            <motion.div
              className="text-center text-[#202124] font-bold mt-2 font-mono"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Token limit reached. Thanks for participating in our beta!
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default EnhancedBasquiatChat;