import React from 'react';
import { Chrome, Globe, MonitorPlay } from 'lucide-react';
import { motion } from 'framer-motion';

const BrowserCompatibility = () => {
  const browsers = [
    {
      name: 'Chrome',
      icon: <Chrome size={48} />,
      link: 'https://www.google.com/chrome'
    },
    {
      name: 'Firefox',
      icon: <Globe size={48} />,  // Using Globe instead of Firefox icon
      link: 'https://www.mozilla.org/firefox'
    },
    {
      name: 'Edge',
      icon: <MonitorPlay size={48} />,  // Using MonitorPlay instead of Monitor
      link: 'https://www.microsoft.com/edge'
    }
  ];

  return (
    <div className="min-h-screen w-full bg-[#F7F7EF] flex flex-col items-center justify-center font-mono p-4">
      {/* Header */}
      <header className="text-5xl font-bold mb-12 flex flex-col items-center justify-center">
        <span className="text-[#202124] px-4 py-3 transform -rotate-2 inline-flex items-baseline">
          <span style={{fontFamily: "'Permanent Marker', cursive"}}>R</span>
          <span className="font-mono">EEL</span>
          <span style={{fontFamily: "'Permanent Marker', cursive"}}>V</span>
          <span className="font-mono">OO</span>
          <span style={{fontFamily: "'Permanent Marker', cursive"}}>D</span>
          <span className="font-mono">OO</span>
        </span>
      </header>

      {/* Main Content */}
      <motion.div 
        className="max-w-2xl w-full space-y-8 text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-[#202124] text-[#F7F7EF] p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold mb-4">Browser Compatibility Notice</h1>
          <p className="mb-6">
            We apologize, but REELVOODOO is currently not optimized for Safari. 
            For the best experience, please use one of these browsers:
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {browsers.map((browser) => (
              <motion.a
                key={browser.name}
                href={browser.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center p-4 bg-[#F7F7EF] text-[#202124] rounded-lg hover:bg-opacity-90 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {browser.icon}
                <span className="mt-2 font-bold">{browser.name}</span>
              </motion.a>
            ))}
          </div>
        </div>

        <p className="text-sm text-[#202124] mt-4">
          We're working on Safari compatibility and appreciate your understanding.
        </p>
      </motion.div>

      {/* Footer */}
      <footer className="mt-auto pt-8 text-sm text-[#202124]">
        <div className="flex flex-col items-center justify-center">
          <span>REELVOODOO 2024 | Elevating your movie experience</span>
          <div className="mt-2 text-xs space-x-4">
            <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
            <a href="/terms-of-service" className="hover:underline">Terms of Service</a>
            <a href="/cookie-policy" className="hover:underline">Cookie Policy</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BrowserCompatibility;