import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ReactMarkdown from 'react-markdown';
import EnhancedBasquiatChat from './EnhancedBasquiatChat';
import { savePendingConsents } from '../LegalComponents'; 

const BasquiatChatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [socket, setSocket] = useState(null);
  const [chatId, setChatId] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isBotThinking, setIsBotThinking] = useState(false);
  const [tokensRemaining, setTokensRemaining] = useState(null);
  const [hasReachedLimit, setHasReachedLimit] = useState(false);

  useEffect(() => {
    const handleInitialLoad = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('pending_consent') === 'true') {
        try {
          await savePendingConsents();
          // Remove the pending_consent parameter from URL without refreshing
          const newUrl = window.location.pathname;
          window.history.replaceState({}, document.title, newUrl);
        } catch (error) {
          // console.error('Error saving consents:', error);
        }
      }
    };

    handleInitialLoad();
    // Initialize socket connection
    // console.log('Environment Variables:', process.env);
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      withCredentials: true,
      transports: ['websocket', 'polling'],
      reconnectionAttempts: 5,
      timeout: 10000
    });

    // Socket event handlers
    newSocket.on('session_expired', (data) => {
      // console.log('Session expired:', data.message);
      navigate('/landing');
    });

    newSocket.on('connect_error', (error) => {
      // console.error('Socket connection error:', error);
    });
    
    newSocket.on('connect_timeout', () => {
      // console.error('Socket connection timeout');
    });

    newSocket.on('connect', () => {
      // console.log('Connected to server');
      newSocket.emit('start_chat', { user_id: null });
    });

    newSocket.on('chat_started', (data) => {
      // console.log('chat started', data);
      setChatId(data.chat_id);
      newSocket.emit('join', { room: data.chat_id });
    });

    newSocket.on('message', (data) => {
      setMessages(prev => [...prev, { text: data.text, user: false }]);
      setIsBotThinking(false);
    });

    // Token-related event handlers
    newSocket.on('error', (data) => {
      if (data.message.includes('token limit')) {
        setHasReachedLimit(true);
      }
    });

    newSocket.on('tokens_updated', (data) => {
      setTokensRemaining(data.tokens_remaining);
      setHasReachedLimit(data.tokens_remaining <= 0);
    });

    setSocket(newSocket);

    // Fetch initial token count
    fetch('/api/get_tokens_remaining')
      .then(res => res.json())
      .then(data => {
        setTokensRemaining(data.tokens_remaining);
        setHasReachedLimit(data.tokens_remaining <= 0);
      })
      .catch(err => console.error('Error fetching tokens:', err));

    return () => {
      if (socket && socket.connected) {
        // console.log('Disconnecting socket in cleanup');
        socket.disconnect();
      }
    };
  }, []);
  
  const handleSend = () => {
    if (input.trim() && socket && chatId && !hasReachedLimit) {
      // console.log("sending input", input);
      socket.emit('send_message', { message: input, chat_id: chatId });
      setMessages(prev => [...prev, { text: input, user: true }]);
      setInput('');
      setIsBotThinking(true);
    }
  };

  const handleLogout = async () => {
    // console.log('1. Logout process started');
    setIsLoggingOut(true);
    try {
      // console.log('2. Calling logout function');
      await logout();
      // console.log('3. Logout function completed');
  
      if (socket) {
        // console.log('4. Checking socket state');
        if (socket.connected) {
          // console.log('5. Socket is connected, disconnecting');
          socket.disconnect();
        } else {
          // console.log('5. Socket is already disconnected');
        }
      }
  
      // console.log('6. Attempting navigation');
      navigate('/landing');
      // console.log('7. Navigation function called');
    } catch (error) {
      // console.error('8. Logout failed:', error);
    } finally {
      setIsLoggingOut(false);
      // console.log('9. Logout process completed');
    }
  };

  return (
    <div style={{ 
      minHeight: '100vh', 
      width: '100%',
      backgroundColor: '#F7F7EF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
      position: 'relative' 
    }}>
      <EnhancedBasquiatChat
        messages={messages}
        input={input}
        setInput={setInput}
        handleSend={handleSend}
        handleLogout={handleLogout}
        isBotThinking={isBotThinking}
        tokensRemaining={tokensRemaining}
        hasReachedLimit={hasReachedLimit}
      />
    </div>
  );
};

export default BasquiatChatbot;