import React from 'react';

const TermsOfService = () => {
  const lastUpdated = "October 28, 2024";

  return (
    <div className="min-h-screen bg-[#F7F7EF] p-8">
      <div className="max-w-4xl mx-auto font-mono">
        <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
        <p className="text-sm mb-8">Last Updated: {lastUpdated}</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="mb-4">By accessing or using the ReelVoodoo website and services ("Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the Service.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
            <p className="mb-4">ReelVoodoo is an AI-powered movie recommendation platform that provides personalized movie suggestions through interactive chat conversations. The Service is currently in beta testing with limited availability.</p>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">Key Features:</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>Interactive chat interface with AI</li>
                <li>Personalized movie recommendations</li>
                <li>Token-based usage system</li>
                <li>Google account integration</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. Eligibility</h2>
            <ul className="list-disc pl-5 space-y-2">
              <li>Must be at least 13 years of age</li>
              <li>Must have a valid Google account</li>
              <li>Must provide accurate information</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Beta Program Terms</h2>
            <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
              <h3 className="font-semibold mb-2">Token System:</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>Maximum of 10,000 tokens per user</li>
                <li>Token limits may be modified</li>
                <li>No guarantee of service continuation after beta</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">Beta Features:</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>Access to experimental features</li>
                <li>Features may change without notice</li>
                <li>Service stability not guaranteed</li>
                <li>Feedback collection for improvement</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. User Accounts</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold mb-2">Account Creation:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Google sign-in required</li>
                  <li>Responsible for account security</li>
                  <li>Accountable for all account activity</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Account Termination:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>We may terminate accounts at our discretion</li>
                  <li>Users may terminate their account anytime</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Limitations of Liability</h2>
            <p className="mb-4">Our movie recommendations are provided "as is" without any warranties. We are not liable for:</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>Content of recommended movies</li>
              <li>Accuracy of AI-generated suggestions</li>
              <li>Availability of recommended content</li>
              <li>Service interruptions or modifications</li>
              <li>Direct, indirect, or consequential damages</li>
              <li>Lost profits or data loss</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Privacy and Data</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold mb-2">Data Collection:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>User interactions are logged and analyzed</li>
                  <li>Movie preferences are stored</li>
                  <li>Viewing history may be retained</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-2">Cookies:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Essential cookies for service functionality</li>
                  <li>Manageable cookie preferences</li>
                  <li>Some features require cookies</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Contact Information</h2>
            <p>For questions about these Terms, contact: <a href="mailto:hello@reelvoodoo.com" className="text-blue-600 hover:underline">hello@reelvoodoo.com</a></p>
          </section>

          <section className="border-t pt-8">
            <p className="text-sm text-gray-600">
              These Terms of Service constitute the entire agreement between you and ReelVoodoo regarding the Service. By using ReelVoodoo, you acknowledge that you have read, understood, and agree to be bound by these Terms.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;