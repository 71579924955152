import React from 'react';

const PrivacyPolicy = () => {
  const lastUpdated = "October 28, 2024";

  return (
    <div className="min-h-screen bg-[#F7F7EF] p-8">
      <div className="max-w-4xl mx-auto font-mono">
        <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-sm mb-8">Last Updated: {lastUpdated}</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="mb-4">
              ReelVoodoo ("we," "our," or "us") respects your privacy and is committed to protecting your personal data. 
              This privacy policy explains how we collect, use, and safeguard your information when you use our AI-powered 
              movie recommendation service.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
            <div className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Personal Information:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Google account information (email, profile name)</li>
                  <li>User-provided preferences and interests</li>
                  <li>Chat interactions and messages</li>
                  <li>Movie preferences and viewing history</li>
                  <li>Usage patterns and behaviors</li>
                </ul>
              </div>
              
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">Automatically Collected Information:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>IP address and location data</li>
                  <li>Device information (browser type, operating system)</li>
                  <li>Cookie data and session information</li>
                  <li>Usage metrics and token consumption</li>
                  <li>Time stamps of interactions</li>
                </ul>
              </div>

              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">AI Interaction Data:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Chat conversations with our AI system</li>
                  <li>Movie preferences and reactions</li>
                  <li>Recommendation responses and feedback</li>
                  <li>User engagement patterns</li>
                  <li>Feature usage statistics</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold mb-2">Primary Uses:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Providing personalized movie recommendations</li>
                  <li>Improving AI response accuracy</li>
                  <li>Enhancing user experience</li>
                  <li>Managing token allocation and usage</li>
                  <li>Maintaining account security</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="font-semibold mb-2">Protection Measures:</h3>
              <ul className="list-disc pl-5 space-y-2">
                <li>Encryption of personal data</li>
                <li>Secure data transmission</li>
                <li>Regular security assessments</li>
                <li>Access control and authentication</li>
                <li>Monitoring for unauthorized access</li>
              </ul>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">5. Your Rights and Choices</h2>
            <div className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h3 className="font-semibold mb-2">You have the right to:</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Access your personal data</li>
                  <li>Export your account data</li>
                  <li>Modify your account settings</li>
                  <li>Delete your account</li>
                  <li>Manage cookie preferences</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">6. Children's Privacy</h2>
            <p className="mb-4">
              Our service is restricted to users 13 and older. We do not knowingly collect or maintain information 
              from persons under 13 years of age.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">7. Changes to This Policy</h2>
            <p className="mb-4">
              We may update this privacy policy from time to time. We will notify you of any changes by posting 
              the new privacy policy on this page and updating the "Last Updated" date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">8. Contact Information</h2>
            <p>
              For privacy-related inquiries, please contact us at:{' '}
              <a href="mailto:hello@reelvoodoo.com" className="text-blue-600 hover:underline">
                privacy@reelvoodoo.com
              </a>
            </p>
          </section>

          <section className="border-t pt-8">
            <p className="text-sm text-gray-600">
              By using ReelVoodoo, you acknowledge that you have read and understood this Privacy Policy 
              and agree to our collection, use, and disclosure practices described above.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;