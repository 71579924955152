import React, { useState, useEffect } from 'react';
import { Shield, Cookie, X, ToggleLeft, ToggleRight } from 'lucide-react';
import { motion } from 'framer-motion';

// Cookie Preferences Modal Component
const CookiePreferencesModal = ({ isOpen, onClose, onSave }) => {
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
    preferences: false
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-[#202124]">Cookie Preferences</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-6">
            {/* Cookie type toggles */}
            <div className="flex justify-between items-center pb-4 border-b">
              <div>
                <h3 className="font-semibold mb-1">Necessary Cookies</h3>
                <p className="text-sm text-gray-600">Required for the website to function properly.</p>
              </div>
              <div className="text-gray-400">
                <ToggleRight className="w-6 h-6" />
              </div>
            </div>

            {Object.entries({
              analytics: "Help us improve our website",
              marketing: "Deliver more relevant recommendations",
              preferences: "Remember your settings"
            }).map(([key, description]) => (
              <div key={key} className="flex justify-between items-center pb-4 border-b">
                <div>
                  <h3 className="font-semibold mb-1">{key.charAt(0).toUpperCase() + key.slice(1)} Cookies</h3>
                  <p className="text-sm text-gray-600">{description}</p>
                </div>
                <button
                  onClick={() => setPreferences(prev => ({ ...prev, [key]: !prev[key] }))}
                  className="text-[#202124]"
                >
                  {preferences[key] ? <ToggleRight className="w-6 h-6" /> : <ToggleLeft className="w-6 h-6" />}
                </button>
              </div>
            ))}
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <button onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800">
              Cancel
            </button>
            <button
              onClick={() => {
                onSave(preferences);
                onClose();
              }}
              className="px-4 py-2 bg-[#202124] text-white rounded-md hover:bg-opacity-90"
            >
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Cookie Consent Banner Component
export const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setShowBanner(false);
    }
  }, []);

  const handleAcceptAll = () => {
    const allConsent = {
      necessary: true,
      analytics: true,
      marketing: true,
      preferences: true,
      timestamp: new Date().toISOString(),
      pending: true // Flag to indicate this needs to be saved to DB after auth
    };
    localStorage.setItem('cookieConsent', JSON.stringify(allConsent));
    setShowBanner(false);
  };

  const handleSavePreferences = (preferences) => {
    const savedPreferences = {
      ...preferences,
      timestamp: new Date().toISOString(),
      pending: true // Flag to indicate this needs to be saved to DB after auth
    };
    localStorage.setItem('cookieConsent', JSON.stringify(savedPreferences));
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 bg-[#202124] text-[#F7F7EF] p-4 shadow-lg z-50">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-start justify-between gap-4">
            <div className="flex items-start gap-3">
              <Cookie className="w-5 h-5 mt-1 flex-shrink-0" />
              <div className="space-y-2">
                <p className="text-sm">
                  We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
                </p>
                <div className="space-x-4">
                  <button
                    onClick={handleAcceptAll}
                    className="bg-[#F7F7EF] text-[#202124] px-4 py-2 rounded-md text-sm font-medium hover:bg-opacity-90"
                  >
                    Accept All
                  </button>
                  <button
                    onClick={() => setShowPreferences(true)}
                    className="text-[#F7F7EF] underline text-sm"
                  >
                    Customize Preferences
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CookiePreferencesModal
        isOpen={showPreferences}
        onClose={() => setShowPreferences(false)}
        onSave={handleSavePreferences}
      />
    </>
  );
};

// Age Verification Modal Component
export const AgeVerificationModal = ({ onAccept }) => {
  const [showModal, setShowModal] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  const handleAcceptAge = () => {
    const ageVerification = {
      verified: true,
      timestamp: new Date().toISOString(),
      pending: true // Flag to indicate this needs to be saved to DB after auth
    };
    localStorage.setItem('ageVerification', JSON.stringify(ageVerification));
    setShowModal(false);
    onAccept();
  };

  const handleExit = () => {
    setIsExiting(true);
    setTimeout(() => {
      window.location.href = "https://www.commonsensemedia.org/movie-reviews";
    }, 2000);
  };

  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-md w-full space-y-4">
        {!isExiting ? (
          <>
            <h2 className="text-xl font-bold text-[#202124]">Age Verification Required</h2>
            <p className="text-sm text-gray-600">
              You must be at least 13 years old to use REELVOODOO. By continuing, you confirm that you meet this requirement.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleExit}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                I'm under 13
              </button>
              <button
                onClick={handleAcceptAge}
                className="bg-[#202124] text-white px-4 py-2 rounded-md text-sm hover:bg-opacity-90"
              >
                I confirm I am 13 or older
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-xl font-bold text-[#202124]">Redirecting...</h2>
            <p className="text-sm text-gray-600">
              We're redirecting you to Common Sense Media, where you can find age-appropriate movie recommendations. Thank you for your honesty!
            </p>
            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#202124]"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// Data Processing Banner Component
export const DataProcessingBanner = () => (
  <div className="bg-[#202124] text-[#F7F7EF] py-2 px-4 text-sm text-center">
    <p>
      Data processed in the {process.env.REACT_APP_DATA_PROCESSING_LOCATION || 'United States'}.
      By using our service, you agree to our{' '}
      <a href="/privacy-policy" className="underline">Privacy Policy</a>.
    </p>
  </div>
);

// Helper function to save pending consents after authentication
export const savePendingConsents = async () => {
  try {
    // Check cookie consent
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent) {
      const consentData = JSON.parse(cookieConsent);
      
      // console.log('Found cookie consent in localStorage:', consentData);
      
      if (consentData.pending) {
        // console.log('Saving cookie consent to database...');
        const response = await fetch('/api/user/consent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            consent_type: 'cookies',
            consent_value: true,
            preferences: consentData
          }),
          credentials: 'include'
        });
        
        if (response.ok) {
          // console.log('Cookie consent saved successfully');
          // Update localStorage to remove pending flag
          delete consentData.pending;
          localStorage.setItem('cookieConsent', JSON.stringify(consentData));
        } else {
          // console.error('Failed to save cookie consent:', await response.json());
        }
      }
    }

    // Check age verification
    const ageVerification = localStorage.getItem('ageVerification');
    if (ageVerification) {
      const ageData = JSON.parse(ageVerification);
      
      // console.log('Found age verification in localStorage:', ageData);
      
      if (ageData.pending) {
        // console.log('Saving age verification to database...');
        const response = await fetch('/api/user/consent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            consent_type: 'age_verification',
            consent_value: true
          }),
          credentials: 'include'
        });

        if (response.ok) {
          // console.log('Age verification saved successfully');
          // Update localStorage to remove pending flag
          delete ageData.pending;
          localStorage.setItem('ageVerification', JSON.stringify(ageData));
        } else {
          // console.error('Failed to save age verification:', await response.json());
        }
      }
    }
  } catch (error) {
    // console.error('Error saving pending consents:', error);
    throw error;
  }
};