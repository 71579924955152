import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const { checkAuthStatus } = useAuth();
  
  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase());
      
      if (isSafari) {
        // Safari flow: wait for auth check to complete
        await checkAuthStatus();
        navigate('/chat');
      } else {
        // Non-Safari flow: send success message to opener
        if (window.opener) {
          window.opener.postMessage('success', '*');
        }
        window.close();
      }
    };
    
    handleCallback();
  }, [navigate, checkAuthStatus]);
  
  return <div>Completing authentication...</div>;
};

export default OAuthCallback;