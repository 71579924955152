import React, { useState, useEffect } from 'react';
import { MessageSquare, Lightbulb, Glasses, Brain, Sparkles, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import './GoogleSignInButton.css';
import { 
  CookieConsent, 
  DataProcessingBanner, 
  savePendingConsents
} from '../components/LegalComponents';
import BrowserCompatibility from './BrowserCompatibility';

// Beta Banner Component
const BetaBanner = () => {
  return (
    <motion.div 
      className="bg-[#202124] text-[#F7F7EF] p-4 rounded-lg shadow-lg max-w-2xl w-full mb-8"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0 mt-0.5">
          <AlertCircle className="h-5 w-5" />
        </div>
        <div className="flex-1">
          <h3 className="font-bold mb-1">Limited Beta Access</h3>
          <p className="text-sm opacity-90">
            We're currently in beta testing with limited availability. Join us in this exclusive phase as we shape the future of personalized film discovery. Experience our AI-powered recommendations while we fine-tune the experience.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

// Feature Card Component
const FeatureCard = ({ icon, title, description }) => (
  <motion.div 
    className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center text-center"
    whileHover={{ scale: 1.05 }}
  >
    <div className="text-3xl mb-2">{icon}</div>
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    <p className="text-sm">{description}</p>
  </motion.div>
);

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try a different browser.</h1>;
    }
    return this.props.children;
  }
}

const BasquiatLandingPage = () => {
  const [hasConsented, setHasConsented] = useState(false);
  const [showGoogleAuth, setShowGoogleAuth] = useState(false);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  useEffect(() => {
    // Safari detection
    const detectSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      const isSafari = (
        ua.includes('safari') && 
        !ua.includes('chrome') && 
        !ua.includes('android')
      );
      console.log('UserAgent:', ua);
      console.log('Is Safari?:', isSafari);
      setIsSafariBrowser(isSafari);
    };

    detectSafari();
  }, []);

  // Handle Google Authentication
  const startGoogleAuth = () => {
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const authUrl = process.env.REACT_APP_API_URL;
    
    const popup = window.open(
      `${authUrl}/auth/google`,
      'GoogleLogin',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  
    window.addEventListener('message', async (event) => {
      if (event.data === 'success') {
        try {
          await savePendingConsents();
        } catch (error) {
          console.error('Error saving consents:', error);
        }
        popup.close();
        window.location.href = '/chat';
      }
    }, false);
  };

  // Return Browser Compatibility page for Safari users
  if (isSafariBrowser) {
    console.log('Rendering Safari compatibility page');
    return <BrowserCompatibility />;
  }

  // Main landing page render
  return (
    <>
      <DataProcessingBanner />
      
      <div className="min-h-screen w-full bg-[#F7F7EF] flex flex-col items-center justify-center font-mono overflow-hidden relative p-4">
        {/* Header with Beta Badge */}
        <header className="text-5xl font-bold mb-4 flex flex-col items-center justify-center">
          <span className="text-[#202124] px-4 py-3 transform -rotate-2 inline-flex items-baseline">
            <span style={{fontFamily: "'Permanent Marker', cursive"}}>R</span>
            <span className="font-mono">EEL</span>
            <span style={{fontFamily: "'Permanent Marker', cursive"}}>V</span>
            <span className="font-mono">OO</span>
            <span style={{fontFamily: "'Permanent Marker', cursive"}}>D</span>
            <span className="font-mono">OO</span>
          </span>
          <motion.div 
            className="flex items-center space-x-2 text-sm bg-[#202124] text-[#F7F7EF] px-3 py-1 rounded-full mt-2"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <Sparkles className="h-4 w-4" />
            <span>BETA</span>
          </motion.div>
        </header>

        <BetaBanner />

        <motion.div 
          className="text-2xl mb-8 text-center max-w-2xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Your personal movie matchmaker, powered by AI
        </motion.div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8 max-w-4xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <FeatureCard 
            icon={<MessageSquare className="text-[#202124]" />}
            title="Smart Conversations"
            description="Engage in real dialogue about films, tailored to your mood and tastes."
          />
          <FeatureCard 
            icon={<Lightbulb className="text-[#202124]" />}
            title="One Perfect Pick"
            description="Get the single best movie recommendation for your moment."
          />
          <FeatureCard 
            icon={<Glasses className="text-[#202124]" />}
            title="Beyond Mainstream"
            description="Discover hidden gems and cult classics you might have missed."
          />
          <FeatureCard 
            icon={<Brain className="text-[#202124]" />}
            title="Learning and Evolving"
            description="I learn and grow with each interaction, refining recommendations over time."
          />
        </motion.div>

        {/* Google Sign In Button */}
        <motion.button
          onClick={startGoogleAuth}
          className="gsi-material-button mb-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
              </svg>
            </div>
            <span className="gsi-material-button-contents">Continue with Google</span>
          </div>
        </motion.button>

        {/* Terms Notice */}
        <motion.p 
          className="text-xs text-center text-gray-600 max-w-md mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          By using REELVOODOO, you confirm that you're 13 or older and agree to our{' '}
          <a href="/terms-of-service" className="underline hover:text-gray-800">Terms of Service</a>{' '}
          and{' '}
          <a href="/privacy-policy" className="underline hover:text-gray-800">Privacy Policy</a>
        </motion.p>

        {/* Footer */}
        <footer className="text-sm text-[#202124] mt-auto">
          <div className="flex flex-col items-center justify-center">
            <span>REELVOODOO 2024 | Elevating your movie experience</span>
            <div className="mt-2 text-xs space-x-4">
              <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
              <a href="/terms-of-service" className="hover:underline">Terms of Service</a>
              <a href="/cookie-policy" className="hover:underline">Cookie Policy</a>
            </div>
          </div>
        </footer>
      </div>

      <CookieConsent />
    </>
  );
};

// Export wrapped in ErrorBoundary
export default function SafeLandingPage() {
  return (
    <ErrorBoundary>
      <BasquiatLandingPage />
    </ErrorBoundary>
  );
}