import React from 'react';
import BasquiatChatInterface from '../components/BasquiatChatbot/ChatInterface';

const ChatPage = () => {
  return (
    <div className="h-screen">
      <BasquiatChatInterface />
    </div>
  );
};

export default ChatPage;