import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const SignupPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('/api/signup', {
        username,
        email,
        password
      }, {
        withCredentials: true // This is important for carrying over the session
      });

      const { access_token, user_id } = response.data;

      // Use the login function from AuthContext to set the user state
      await login({ user: { id: user_id, username }, token: access_token });

      navigate('/chat');
    } catch (err) {
      setError(err.response?.data?.msg || 'An error occurred during signup');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-yellow-300">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl mb-4">Sign Up</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
          required
        />
        <button type="submit" className="w-full p-2 bg-black text-yellow-300 rounded hover:bg-gray-800 transition-colors">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignupPage;